import React from 'react';
import './Header.css';

export default (props) => {
  return (
    <header>
      <div className="header-background" />
      <div className="logo"/>
    </header>
  )
};
