export default {
    "en": {
        "detail.download_button": "Download",
        "detail.share_button": "SHARE",
        "intro.instructions.step1": "Tap the upper right arrow in the T.O.P App to get to your member QR Code at \"My Place\".\r",
        "intro.instructions.step2": "Draw a love stick from the love stick holder and scan on the card reader\r. ",
        "intro.instructions.step3": "Click on the arrow in the upper left corner of the T.O.P. App to check your love fortune!\r ",
        "intro.no_member_id": "Please go back and login T.O.P app to view fortune",
        "intro.subtitle": "COME TO T.O.P and draw your Love fortune stick NOW!\r Master Shum will decipher your fortune \r",
        "intro.view_fortune": "Decipher your fortune",
        "listing.date_label": "Date: ",
        "listing.error": "Error",
        "listing.no_photos_message": "Head up to T.O.P's 1/F and try \"Fortune Stick\"",
        "listing.title": "Decipher your fortune",
        "listing.view_instruction_button": "HOW TO"
    },
    "zh-Hant": {
        "detail.download_button": "下載",
        "detail.share_button": "分享",
        "intro.instructions.step1": "打開T.O.P App, 㩒右上角箭咀打開會員頁內嘅QR Code，跟住掃描\r",
        "intro.instructions.step2": "喺愛情籤筒抽出1支籤， 擺上讀卡器上掃瞄解籤\r",
        "intro.instructions.step3": "喺T.O.P App 㩒左上角箭咀, 打開愛情上上籤主頁就可以睇你嘅運程喇!",
        "intro.no_member_id": "請登入 T.O.P 手機程式先再嚟解籤睇運程",
        "intro.subtitle": "去T.O.P求籤預測你嘅愛情運\r沈大師沈卓盈仲會即時同你解籤！\r",
        "intro.view_fortune": "入嚟解籤啦！",
        "listing.date_label": "求籤時間：",
        "listing.error": "錯誤",
        "listing.no_photos_message": "快啲去 T.O.P 1/F 求番支籤啦",
        "listing.title": "搵沈大師同你解籤啦!",
        "listing.view_instruction_button": "點玩"
    },
    "zh-Hans": {
        "detail.download_button": "下载",
        "detail.share_button": "分享",
        "intro.instructions.step1": "打开T.O.P App, 按右上角箭头打开会员页内的QR Code并扫描",
        "intro.instructions.step2": "在爱情签筒抽出竹签，并置于读卡器上扫瞄解签",
        "intro.instructions.step3": "于T.O.P App点按左上角箭头, 打开爱情上上签主页查看运程\r",
        "intro.no_member_id": "请登录T.O.P手机程序再来解签看运程",
        "intro.subtitle": "去T.O.P求签预测你的爱情运\r沈卓盈仲会立即替你解签！\r",
        "intro.view_fortune": "进来解签吧！",
        "listing.date_label": "求签时间：",
        "listing.error": "错误",
        "listing.no_photos_message": "快點去 T.O.P 1/F 求簽吧",
        "listing.title": "请沈大师帮你解签",
        "listing.view_instruction_button": "玩法"
    }
};