import React from 'react';

import './Player.css';

class Player extends React.Component {

  play = (e) => {
    (window.players || []).forEach(p => p.pause());
    e.preventDefault();
    this.player.play();
    try {
      this.player.requestFullscreen();
    } catch (e) {
      // may not exist for non chrome browser
    }
    this.player.controls = true;
    this.controls.hidden = true;
  };

  stop = () => {
    this.player.controls = false;
    this.controls.hidden = false;
  };

  componentWillUnmount() {
    if (this.player) {
      this.player.removeEventListener('pause', this.stop);
      window.players = (window.players || []).filter(p => p !== this.player);
    }
  }

  render() {
    return (
      <div className={`video-wrapper ${this.props.bordered ? 'video-wrapper--border' : ''}`}>
        <video className="video" controlsList="nodownload" poster={this.props.poster} ref={(p) => {
          if (p) {
            window.players = (window.players || []).concat(p);
            p.addEventListener("pause", this.stop);
          } else {
            window.players = (window.players || []).filter(p => p !== this.player);
          }
          this.player = p;
        }}>
          <source
            src={`${this.props.src}`}
            type="video/mp4"/>
          Your browser does not support HTML5 video.
        </video>
        <div className="video-control" ref={(e) => this.controls = e}>
          <button className="btn_play" onClick={this.play}/>
          {
            this.props.title ?
              <div className="title">{this.props.title}</div>
              : null
          }
        </div>
      </div>
    )
  }

}

export default Player;
