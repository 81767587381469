import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import ListPhotosPage from './ListPhotosPage';
import IntroPage from './IntroPage';
import messages from './i18n/data';
import queryString from 'query-string';

class App extends Component {
  render() {
    let { lang = 'en' } = queryString.parse(window.location.search);

    if (lang === 'sc') {
      lang = 'zh-Hans';
    } else if (lang === 'tc') {
      lang = 'zh-Hant';
    } else {
      lang = 'en';
    }

    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        <div className={`container ${lang}`}>
          <main>
            <Router>
              <div>
                <Switch>
                  <Route path="/" exact component={IntroPage}/>
                  <Route path="/photos" exact component={ListPhotosPage}/>
                </Switch>
              </div>
            </Router>
          </main>
        </div>
      </IntlProvider>
    );
  }
}

export default App;
