import React from 'react';
import ReactDOM from 'react-dom';
import 'bulma/bulma.sass';
import "video-react/dist/video-react.css";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {addLocaleData} from 'react-intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import ReactGA from 'react-ga';

addLocaleData([...en, ...zh]);

ReactGA.initialize('UA-16724696-50');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
