import React from 'react';
import './IntroPage.css';
import { Link } from 'react-router-dom';
import Header from './Header';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import alert from './images/ic-alert-red-small.svg';
import instruction1 from './images/ic-instruction-01-f@3x.png';
import instruction2 from './images/ic-instruction-02-f.svg';
import instruction3 from './images/ic-instruction-03-f.svg';
import ReactGA from 'react-ga';
import Player from './Player';

class IntroPage extends React.Component {

  state = {
    topimid: null,
  };

  componentDidMount() {
    const { topimid } = queryString.parse(this.props.location.search);
    this.setState({
      topimid,
    });

    ReactGA.pageview('Introduction');
  };

  render() {
    const { topimid } = this.state;

    return (
      <div className="wrapper-intro">
        <Header/>
        <div className="intro-content">
          {topimid ? (
              <Link
                to={{
                  pathname: '/photos',
                  search: `?topimid=${encodeURIComponent(topimid)}`,
                }}
                className="button button-primary">
                <FormattedMessage id="intro.view_fortune"/>
              </Link>
            ) :
            (
              <div className="intro-noid">
                <img className="intro-noid-icon" src={alert}/>
                <FormattedMessage id="intro.no_member_id"/>
              </div>
            )}
          <div className="intro-instructions-wrapper">
            <Player src="https://player.vimeo.com/external/314242973.sd.mp4?s=941f951b92784fe86d8981da78bddbe10e0daabf&profile_id=165" poster="https://i.vimeocdn.com/video/756022632_640.jpg" />
            <p className="intro-subtitle">
              <FormattedMessage id="intro.subtitle"/>
            </p>
            <ol className="intro-instructions">
              <li className="intro-instruction">
                <span className="intro-instruction-number">1.</span>
                <img className="intro-instruction-icon" src={instruction1}/>
                <FormattedMessage id="intro.instructions.step1"/>
              </li>
              <li className="intro-instruction">
                <span className="intro-instruction-number">2.</span>
                <img className="intro-instruction-icon" src={instruction2}/>
                <FormattedMessage id="intro.instructions.step2"/>
              </li>
              <li className="intro-instruction">
                <span className="intro-instruction-number">3.</span>
                <img className="intro-instruction-icon" src={instruction3}/>
                <FormattedMessage id="intro.instructions.step3"/>
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

export default IntroPage;
