import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import queryString from 'query-string'
import alert from './images/ic-alert-red.svg';
import Header from './Header';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import ReactGA from 'react-ga';
import Player from './Player';
import dayjs from 'dayjs';

const url = process.env.NODE_ENV === 'production' ? '' : 'https://link-fortune.redso.com.hk';

class ListPhotosPage extends React.Component {

  state = {
    topimid: null,
    fortunes: [],
    isLoading: false,
    error: null,
  };

  async componentDidMount() {
    ReactGA.pageview('Fortunes Listing');
    const { topimid } = queryString.parse(this.props.location.search);
    if (topimid) {
      this.setState({
        topimid,
        isLoading: true,
      });
      try {
        let params = {
          topimid,
        };
        const response = await axios.get(`${url}/api/getFortunes`, {
          params,
        });
        const fortunes = response.data.result;
        this.setState({
          fortunes,
          isLoading: false,
          error: null,
        });
      } catch (e) {
        this.setState({
          fortunes: [],
          error: e,
          isLoading: false,
        });
      }
    }
  }

  renderContent() {
    const { fortunes, topimid, isLoading, error } = this.state;
    if (!topimid) {
      return <div>User ID is missing</div>
    }
    if (isLoading) {
      return <div className="loader"/>
    }

    if (error) {
      return <div className="gallery-empty">
        <img src={alert} className="gallery-empty-alert"/>
        <p><FormattedHTMLMessage id="listing.error"/></p>
      </div>
    }

    if (fortunes.length === 0) {
      return <div className="gallery-empty">
        <img src={alert} className="gallery-empty-alert"/>
        <p><FormattedHTMLMessage id="listing.no_photos_message"/></p>
      </div>
    }

    return (
      <div className="fortunes">
        {fortunes.map((f) =>
          <div key={f.date} className="gallery-item">
            <Player bordered src={f.videoURL} title={`${this.props.intl.formatMessage({id:'listing.date_label'})}${dayjs(f.date).format('DD/MM/YYYY hh:mmA')}`} poster={f.thumbnailURL}/>
          </div>)
        }
      </div>
    );
  }

  render() {
    const { topimid } = this.state;
    return (
      <div className="wrapper">
        <Header/>
        <h1><FormattedMessage id="listing.title"/></h1>
        <div>
          {this.renderContent()}
        </div>
        <footer>
          <Link to={{
            pathname: '/',
            search: `?topimid=${encodeURIComponent(topimid)}`,
          }} className="button-primary button-intro"><FormattedMessage
            id="listing.view_instruction_button"/></Link>
        </footer>
      </div>
    )
  }

}

export default injectIntl(ListPhotosPage);
